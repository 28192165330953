import React, { useEffect, useState } from 'react';

import { getArticlesList } from '../../../global/api/articles';

import { Spinner } from '../../../global/components/Spinner';

import { useArticlesState } from '../../../global/providers/ArticlesProvider';

import ArticleAsset from '../assets/ArticleAsset';

import AbstractScreen from './AbstractScreen';

import ArticleAssetFeatured from '../assets/ArticleAssetFeatured';

import ArticleAssetTrending from '../assets/ArticleAssetTrending';

const ArticlesScreen = () => {

    const [ articlesState, articlesDispatch ] = useArticlesState();

    const [ searchingForNewArticles, setSearchingForNewArticles ] = useState( false )

    const [ featured, setFeatured ] = useState( )

    const [ trending, setTrending ] = useState( )

    const [ older, setOlder ] = useState( )

    useEffect( () => {

        if( articlesState.articlesList.length == 0 ) {

            getArticlesList().then( data => {
    
                setSearchingForNewArticles( true );
    
                if( Array.isArray( data ) ){
    
                    articlesDispatch( { type: "setArticlesList", data } );
                }
    
                setSearchingForNewArticles( false );
            } )
        }
    }, [ ] )


    useEffect( () => {

        if( articlesState.articlesList.length >= 0 ) {

            setFeatured( articlesState.articlesList[ 0 ] )
        }

        if( articlesState.articlesList.length > 1 ) {

            setTrending( [ ...articlesState.articlesList ].slice( 1, 4 ) )
        }

        if( articlesState.articlesList.length > 4 ) {

            setOlder( [ ...articlesState.articlesList ].slice( 4 ) )
        }
    }, [ articlesState.articlesList ] )

    
    return(
        <AbstractScreen id={ 'articles' } className="w-100">
            <div className="d-flex flex-column gap-10 pb-4 pt-1">
                <div className="d-flex gap-10">
                    <h5 className="bold">TRENDING NOW</h5>
                    { searchingForNewArticles && <div className="pos-abs ml-auto d-flex align-items-center justify-content-center pr-2" style={{ right:0, gap:20, borderRadius:20 }}>
                        <h6 className="small">Finding new articles</h6>
                        <Spinner />
                    </div> }
                </div>
                <div className="d-flex gap-20">
                    <div className="col-6">
                        { featured && <ArticleAssetFeatured { ...articlesState.articlesList[ 0 ] } /> }
                    </div>
                    <div className="flex-grow-1 d-flex flex-column gap-10">
                        { trending && trending.map( article => (
                            <ArticleAssetTrending key={ `featured-article-${ article.id }` } { ...article } />
                        ) ) }
                    </div>
                </div>
                <div className="d-flex flex-column gap-10 pt-2">
                    <h5 className="bold">MORE NEWS</h5>
                    <div className="asset-grid col-12">
                        { older && older.map( article => (
                            <ArticleAsset key={ `article-${ article.id }` } { ...article } />
                        ) ) }
                    </div>
                </div>
            </div>
        </AbstractScreen>
    )
}

export default ArticlesScreen;
import React, {useState} from 'react';

import PropTypes from 'prop-types';

import { section_lookup } from '../helpers';

import AngleLeftIcon from '../../global/components/icon/AngleLeftIcon';

import AngleRightIcon from '../../global/components/icon/AngleRightIcon';

const PageBanner = ( { sections  } ) => {

    const [ currentIndex, setCurrentIndex ] = useState( 0 );

    const nextSection = () => {

        let next = currentIndex + 1;

        if( next > sections.length - 1 ) next = 0;

        setCurrentIndex( next )
    }

    const prevSection = () => {

        let prev = currentIndex - 1;

        if( prev < 0 ) prev = sections.length - 1;

        setCurrentIndex( prev )
    }

    const SectionClass = section_lookup[ sections && sections[ currentIndex ] ? sections[ currentIndex ].layout.slug : 'top' ];

    return (
        <div className="w-100 overflow-hidden pos-rel">
            { sections && sections[ currentIndex ] && <div className="d-flex align-items-start">
                <SectionClass key={`article-section-${  sections[ currentIndex ].id }`} { ...sections[ currentIndex ] } />
            </div> }
            { sections && sections.length > 1 && <>
                <div onClick={ prevSection } className="pointer p-1 h-100 text-white d-flex align-content-center pos-abs tl-0" ><AngleLeftIcon /></div>
                <div onClick={ nextSection } className="pointer p-1 h-100 text-white d-flex align-content-center justify-content-end pos-abs tr-0" ><AngleRightIcon /></div>
            </>}
        </div>
    )
}

PageBanner.propTypes = {
    sections: PropTypes.array
}

export default PageBanner;
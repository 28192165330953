import React, { useEffect, useState } from 'react';

import { getReleasesList } from '../../../global/api/releases';

import { Spinner } from '../../../global/components/Spinner';

import { useReleasesState } from '../../../global/providers/ReleasesProvider';

import ReleaseAsset from '../assets/ReleaseAsset';

import AbstractScreen from './AbstractScreen';

const ReleasesScreen = () => {

    const [ releasesState, releasesDispatch ] = useReleasesState();

    const [ searchingForNewReleases, setSearchingForNewReleases ] = useState( false )

    useEffect( () => {

        if( releasesState.releasesList.length == 0 ) {

            getReleasesList().then( data => {
    
                setSearchingForNewReleases( true );
    
                if( Array.isArray( data ) ){
    
                    releasesDispatch( { type: "setReleasesList", data } );
                }
    
                setSearchingForNewReleases( false );
            } )
        }
    }, [ ] )

    return(
        <AbstractScreen id={ 'releases' } className="w-100">
            <div className="d-flex flex-column gap-10">
                <div className="d-flex gap-10">
                    <h5 className="bold">RELEASES</h5>
                    { searchingForNewReleases && <div className="pos-abs ml-auto d-flex align-items-center justify-content-center pr-2" style={{ right:0, gap:20, borderRadius:20 }}>
                        <h6 className="small">Finding new releases</h6>
                        <Spinner />
                    </div> }
                </div>
                <div className="asset-grid">
                    { releasesState.releasesList.map( release => (
                        <ReleaseAsset key={ `release-${ release.id }` } { ...release } />
                    ) ) }
                </div>
            </div>
        </AbstractScreen>
    )
}

export default ReleasesScreen;
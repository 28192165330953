import React from 'react';

import PropTypes from "prop-types";

import { useNavigate } from 'react-router';

import SSLogo from '../../../global/components/SSLogo';

const ReleaseAsset = ( { id, title, thumbnail, retail_price, release_date, style, className } ) => {

    const navigate = useNavigate();

    const handleOnView = () => {

        navigate( `/releases/${ id }` )
    }

    return(
        <div className={ `rounded-10 asset release-asset hover-border-admin-accent ${ className }` } style={ style } onClick={ handleOnView }>
            <div className="pb-1 pos-rel pt-2">
                <div className="bg-contain d-flex align-items-center justify-content-center w-100 text-light-gray" style={ { aspectRatio: "16 / 9", backgroundImage: `url(" ${ thumbnail || "" } ")` } }>
                    { title && !thumbnail && <div><SSLogo width={ 60 } /></div> }
                </div>
            </div>
            <div className={`px-1 py-1 d-flex flex-column`} style={ { borderTop:'1px solid #ebebeb', gap: 10 } }>
                <div className="d-flex flex-column" style={{gap:2}}>
                    <h6 className="bold">{ title || '' }</h6>
                </div>
                <div className="d-flex" style={{gap:20}}>
                    { release_date && <div className="d-flex flex-column" style={{gap:2}} >
                        <h6 className="text-gray small">Release Date</h6>
                        <h6 className="text-black bold">{ new Date( release_date ).toLocaleDateString( 'en-us', { day:"numeric", month:"short", year:"numeric" } ) }</h6>
                    </div> }
                    { retail_price && <div className="d-flex flex-column" style={{gap:2}} >
                        <h6 className="text-gray small">Retail</h6>
                        <h6 className="text-black bold">{ `$${ retail_price || ''}` }</h6>
                    </div> }
                </div>
            </div>
        </div>
    )
}

ReleaseAsset.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    retail_price: PropTypes.string,
    release_date: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
}

export default ReleaseAsset;

import React, { useState, useEffect } from 'react';

import { NavLink, useParams } from 'react-router-dom';

import { getArticleDetail } from '../../../global/api/articles';

import { Spinner } from '../../../global/components/Spinner';

import { useArticlesState } from '../../../global/providers/ArticlesProvider';

import SimilarArticlesModule from '../SimilarArticlesModule';

import AbstractScreen from './AbstractScreen';

import { section_lookup } from '../../helpers';

const ArticleDetailScreen = () => {

    const { articleId } = useParams();

    const [ state, setState ] = useState( {} );

    const [ ready, setReady ] = useState( false );

    const [ articlesState, articlesDispatch ] = useArticlesState();

    useEffect( () => {

        setReady( false );

            getArticleDetail( articleId ).then( data => {

                articlesDispatch( { type: 'setArticle', data } );

                setReady( true );
            } )

    }, [ articleId ] )

    useEffect( () => {

        if( articlesState.articleDetails[ articleId ] ){

            setState( { ...articlesState.articleDetails[ articleId ] } )
        }
    }, [ articlesState.articleDetails[ articleId ] ] )

    return (
        <AbstractScreen id={ 'article-detail' }>
            { ready && <div>
                { !window.props.embed && <div className="pb-1 pos-rel">
                    <div className="pos-rel d-flex text-dark-gray" style={{gap:8}}>
                        <NavLink to="/news"><h6 className="small">articles</h6></NavLink><span className="text-gray">|</span><h6 className="small">{ state.slug }</h6>
                    </div>
                </div> }
                <div className="d-flex flex-wrap align-items-start" style={{gap:40}}>
                    <div className="col-12 col-d-9" style={{gap:30}}>
                        <div className="d-flex flex-column">
                            <div className="pb-1 d-flex flex-column" style={{gap:20}}>
                                { state.title && <h3 className="bold">{ state.title }</h3> }
                                { state.description && <div className="article-sections" dangerouslySetInnerHTML={{__html: state.description}} /> }
                            </div>
                            { state.sections && <div className="article-sections d-flex flex-column pb-4" style={{gap:20}}>
                                { state.sections.map( section => {
                                    const SectionClass = section_lookup[ section.layout ? section.layout.slug : 'top' ];
                                    return <SectionClass key={`article-section-${section.id}`} { ...section } />
                                })}
                            </div> }
                        </div>
                    </div>
                    { ( state.id && !window.props.embed ) && <div className="flex-grow-1">
                        <SimilarArticlesModule id={ state.id } keyword={ state.keyword } next={ state.next } />
                    </div> }
                </div>
            </div> || <div className="cover d-flex align-items-center justify-content-center align-content-center"><Spinner /></div> }
        </AbstractScreen>
    )
}

export default ArticleDetailScreen;
import React, { useState, useEffect, useRef } from 'react';

import { NavLink, useParams } from 'react-router-dom';

import { getProductAsks, getProductLinks, getProductDetail } from '../../../global/api/products';

import FlightClubLogo from '../../../global/components/FlightClubLogo';

import GoatLogo from '../../../global/components/GoatLogo';

import { Spinner } from '../../../global/components/Spinner';

import StockXLogo from '../../../global/components/StockXLogo';

import { useProductsState } from '../../../global/providers/ProductsProvider';

import SimilarProductsModule from '../SimilarProductsModule';

import AbstractScreen from './AbstractScreen';

import SizeSelectorModule from '../SizeSelectorModule';

import ArrowRightIcon from '../../../global/components/icon/ArrowRightIcon';

const ProductDetailScreen = () => {

    const { productId } = useParams();

    const [ ready, setReady ] = useState( false );

    const [ state, setState ] = useState( {} );

    const [ productsState, productsDispatch ] = useProductsState();

    const [ pricing, setPricing ] = useState( {} );

    const [ selectedSize, setSelectedSize ] = useState();

    const [ selectedSizePrices, setSelectedSizePrices ] = useState();

    const [ lowestPrices, setLowestPrices ] = useState();

    const getProductAsksTO = useRef();

    const getProductLinksTO = useRef();

    const [ updatingAsks, setUpdatingAsks ] = useState( false );

    const doGetProductAsks = () => {

        if( getProductAsksTO.current ) clearTimeout( getProductAsksTO.current )

        getProductAsksTO.current = setTimeout( () => {

            setUpdatingAsks( true )

            getProductAsks( productId ).then( data => {

                if( data.id && data.asks ){

                    productsDispatch( { type: 'updateProduct', data: { id: data.id, asks: data.asks } } );
                }

                setUpdatingAsks( false );
            } );
        }, 1000 )
    }

    const doGetProductLinks = () => {

        if( getProductLinksTO.current ) clearTimeout( getProductLinksTO.current )

        getProductLinksTO.current = setTimeout( () => {

            setUpdatingAsks( true )

            getProductLinks( productId ).then( data => {

                if( data.id && data.links ){

                    productsDispatch( { type: 'updateProduct', data: { id: data.id, links: data.links } } );
                }

                doGetProductAsks()
            } );

        }, 1000 )
    }

    useEffect( () => {

        setReady( false );

        getProductDetail( productId ).then( data => {

            productsDispatch( { type: 'setProduct', data } );

            if( data.links && typeof data.links == "object" && Object.keys( data.links ).length > 1 ){

                doGetProductAsks();

            } else {

                doGetProductLinks();
            }

            setReady( true );
        } )

    }, [ productId ] )

    useEffect( () => {

        return () => {

            if( getProductAsksTO.current ) clearTimeout( getProductAsksTO.current )
        }
    }, [] )

    useEffect( () => {

        if( productsState.productDetails[ productId ] ){

            setState( { ...productsState.productDetails[ productId ] } )
        }

        setCompareAll( false )
    }, [ productsState.productDetails[ productId ], productId ] )

    useEffect( () => {

        let _pricing = {}

        if( state.asks ){

            let site_keys = Object.keys( state.asks )

            site_keys.forEach( site_key => {

                let site_sizes = state.asks[ site_key ];

                if( site_sizes == null ) site_sizes = {};

                Object.keys( site_sizes ).forEach( size => {

                    if( !_pricing[ size ] ){
                        _pricing[ size ] = {}
                        site_keys.forEach( _site => _pricing[ size ][_site] = null )
                    }

                    _pricing[ size ][ site_key ] = state.asks[ site_key ][ size ];
                })
            })
        }

        setPricing( _pricing );

        let lowest = {}

        if( state.asks ){

            Object.keys( state.asks ).forEach( site_key => {

                let site_sizes = state.asks[ site_key ];

                if( site_sizes == null ) site_sizes = {};

                Object.keys( site_sizes ).forEach( size_key => {

                    let value = site_sizes[ size_key ];

                    if( !lowest[ site_key ] || value < lowest[ site_key ] ){
                        
                        lowest[ site_key ] = value;
                    }
                })
            } )
        }

        setLowestPrices( lowest )

    }, [ state.asks ] )

    useEffect( () => {

        if( selectedSize && pricing ){

            setSelectedSizePrices( pricing[ selectedSize ] )
        }
    }, [ selectedSize ] )

    const logo_class_map = {
        'stockx': StockXLogo,
        'goat': GoatLogo,
        'flightclub': FlightClubLogo
    }

    const [ compareAll, setCompareAll ] = useState( false );

    return (
        <AbstractScreen id={ 'product-detail' }>
            { ready && <div>
                <div className="d-flex pb-1 pos-rel w-100">
                    <div className="pos-rel d-flex text-dark-gray gap-10">
                        <NavLink to="/sneakers"><h6 className="small">sneakers</h6></NavLink><span className="text-gray">|</span><h6 className="small">{ state.slug }</h6>
                    </div>
                    <div className="ml-auto pos-rel" style={{display: updatingAsks ? 'block' : 'none'}}>
                        <div className="pos-abs tl-0"><Spinner /></div>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-start" style={ { gap:30 } }>
                    <div className="col-12 col-d-7 rounded-10 overflow-hidden" style={{border:'1px solid #ebebeb'}}>
                        <div className="bg-white px-2">
                            <h4 className="bold py-2">{ state.title }</h4>
                            { state.image && <div className="d-flex w-100"><img style={{maxWidth:600, margin:'auto'}} src={ state.image } width={'100%'} /></div> }
                        </div>
                        <div style={{borderTop:'1px solid #ebebeb'}}>
                            <div className="d-flex flex-wrap w-100 pt-1 pb-2">
                                <div className="col-12 col-d-7 pt-1 px-2 d-flex flex-column" style={{gap:10}}>
                                    { state.colorway && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Colorway</h6><h6 className="small ml-auto medium text-right">{state.colorway}</h6></div> }
                                    { state.sku && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Style</h6><h6 className="small ml-auto medium text-right">{state.sku}</h6></div> }
                                    { state.make && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Make</h6><h6 className="small ml-auto medium text-right">{state.make}</h6></div> }
                                </div>
                                <div className="col-12 col-d-5 pt-1 px-2 d-flex flex-column" style={{gap:10, borderLeft:'1px solid #ebebeb'}}>
                                    { state.release_date && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Release Date</h6><h6 className="small ml-auto medium text-right">{ new Date( state.release_date ).toLocaleDateString( 'en-us', { day:"numeric", month:"short", year:"numeric" } ) }</h6></div> }
                                    { state.retail_price && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Retail Price</h6><h6 className="small ml-auto medium text-right">${state.retail_price}</h6></div> }
                                    { state.brand && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Brand</h6><h6 className="small ml-auto medium text-right">{ state.brand }</h6></div> }
                                </div>
                            </div>
                            { state.description && <div className="px-2 pt-4 pb-2 text-dark-gray small" dangerouslySetInnerHTML={{__html:state.description}}></div> }
                        </div>
                    </div>
                    <div className="flex-grow-1 pos-rel d-flex flex-column gap-20">
                        <div className="d-flex flex-column" style={{ gap:20 }}>
                            <div className="d-flex flex-column" style={{ gap:20 }}>
                                <div className="d-flex flex-column gap-10">
                                    <div className="d-flex gap-10 align-items-center">
                                        <h5 className="bold lh-1">PRICING</h5>
                                        <div className="ml-2 pos-rel">
                                            <div className="pos-abs">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-10 overflow-hidden d-flex w-100" style={{border:'1px solid #ebebeb'}}>
                                        <button className={`rounded-10 flex-grow-1 text-center p-1 ${ compareAll ? 'text-dark-gray bg-white' : 'text-white bg-black'}`} onClick={()=> setCompareAll( false )} ><h6 className={`small bold`}>By Size</h6></button>
                                        <button className={`rounded-10 flex-grow-1 text-center p-1 ${ !compareAll ? 'text-dark-gray bg-white' : 'text-white bg-black'}`} onClick={()=> setCompareAll( true )} ><h6 className={`small bold`}>Compare All</h6></button>
                                    </div>
                                </div>
                                <div className="w-100 flex-column gap-8"  style={{display: compareAll ? 'none' : 'flex'}}>
                                    <SizeSelectorModule selectedSize={ selectedSize } onSizeSelected={ size => setSelectedSize( size ) } pricing={ pricing } />
                                    { selectedSizePrices && selectedSize && <div className="d-flex flex-column rounded-10 overflow-hidden bg-white py-1" style={{paddingLeft:4, gap:1, border:'1px solid #ebebeb'}}>
                                        { Object.keys( selectedSizePrices ).map( ( site ) => {
                                            let Logo = logo_class_map[ site ];
                                            let change = selectedSizePrices[ site ] - state.retail_price;
                                            let percent_change = Math.round( ( ( change / state.retail_price ) * 100 ) * 100 ) / 100;
                                            return selectedSizePrices[ site ] ? <a style={{padding:'8px 10px'}} key={`selected-price-${site}`} rel="noreferrer" target="_blank" href={ state.links[ site ] } className="site-price align-items-center d-flex p-1 gap-20">
                                                <Logo width={ 24 } />
                                                <h6 className='small upper bold'>{site}</h6>
                                                <div className={`align-items-center d-flex ml-auto text-${ change <= 0 ? 'go' : 'dark-gray'} gap-10`}>
                                                    <ArrowRightIcon style={{transform:`rotate(${ change <= 0 ? 45 : -45 }deg)`}} />
                                                    <h6 className={"extra-bold"}>{ percent_change }%</h6>
                                                </div>
                                                <h6 style={{padding:'8px 20px'}} className={`bold rounded-8 ${ change <= 0 ? 'bg-go text-go' : 'bg-extra-light-gray' }`}>{`$${ selectedSizePrices[ site ] }`}</h6>
                                            </a> : null
                                        })}
                                    </div> || <div>
                                        { lowestPrices && <div className="d-flex flex-column rounded-10 overflow-hidden bg-white py-1"  style={{paddingLeft:4, gap:1, border:'1px solid #ebebeb'}}>
                                            { Object.keys( lowestPrices ).map( ( site ) => {
                                                let Logo = logo_class_map[ site ];
                                                let change = lowestPrices[ site ] - state.retail_price;
                                                let percent_change = Math.round( ( ( change / state.retail_price ) * 100 ) * 100 ) / 100;
                                                return lowestPrices[ site ] ? <a style={{padding:'8px 10px'}} key={`lowest-price-${site}`} rel="noreferrer" target="_blank" href={ state.links[ site ] } className="site-price align-items-center d-flex gap-20">
                                                    <Logo width={ 24 } />
                                                    <h6 className='small upper bold'>{site}</h6>
                                                    <div className={`align-items-center d-flex ml-auto text-${ change <= 0 ? 'go' : 'dark-gray'} gap-10`}>
                                                        <ArrowRightIcon style={{transform:`rotate(${ change <= 0 ? 45 : -45 }deg)`}} />
                                                        <h6 className={"extra-bold"}>{ percent_change }%</h6>
                                                    </div>
                                                    <h6 style={{padding:'8px 20px'}} className={`bold rounded-8 ${ change <= 0 ? 'bg-go text-go' : 'bg-extra-light-gray' }`}>{`$${ lowestPrices[ site ] }`}</h6>
                                                </a> : null
                                            })}
                                        </div> }
                                    </div> }
                                </div>
                                <div className="d-flex flex-column gap-10" style={{ display: compareAll ? 'flex' : 'none' }}>
                                    <div className="bg-white rounded-10" style={{ border: '1px solid #ebebeb', padding:'20px 10px 20px 0px' }}>
                                        <div className="flex-column w-100">
                                            <div className="d-flex flex-column w-100" style={{gap:2}}>
                                                <div className="d-flex w-100 align-items-center text-center" style={{padding:'8px 0px 8px 0px'}}>
                                                    <div className={ 'flex-grow-1' }><h5 className="bold">Size</h5></div>
                                                    { Object.keys( logo_class_map ).map( brandname => {
                                                        let Logo = logo_class_map[ brandname ];
                                                        return <div key={`logo-${brandname}`} className={ 'flex-grow-1' }><Logo width={30} /></div>
                                                    } ) }
                                                </div>
                                                { pricing && Object.keys( pricing ).sort( (a,b) => Number( a ) > Number( b ) ? 1 : -1 ).map( size => {
                                                    let brands = pricing[ size ]; let lowest = 100000;
                                                    Object.keys( brands ).forEach( brandname => {
                                                    
                                                        if( brands[ brandname ] && Number( brands[ brandname ] ) <= lowest ) lowest = Number( brands[ brandname ] );
                                                    });
                                                    return <div key={`compareallsize-${size}`} className="d-flex w-100 align-items-center" style={{gap:2}}>
                                                        <div className={ 'flex-grow-1 medium rounded-8 bg-white text-center' } style={{padding:8}}>{ size }</div>
                                                        { Object.keys( logo_class_map ).map( brandkey => {
                                                            return <div key={`link-${brandkey}`} className={ `flex-grow-1 text-center rounded-8 medium ${ brands[ brandkey ] == lowest ? 'bg-go text-go' : 'bg-extra-light-gray text-dark-gray'}` } style={{padding:8}}><h6 className="bold">{ brands[ brandkey ] ? `$${ brands[ brandkey ] }` : '-'}</h6></div>
                                                    } ) }
                                                    </div>
                                                } ) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="w-100 text-gray tiny">Sneaker Seeker may receive a share of the sale if you click and purchase through one of our links on any of the posts throughout the website.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 pt-2">
                        { state.id && state.make && <SimilarProductsModule id={ state.id } make={ state.make } /> }
                    </div>
                </div>
            </div> }
        </AbstractScreen>
    )
}

export default ProductDetailScreen;
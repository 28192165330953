import { getCookie } from "./utils";

export const getHeaders = ( method, use_api_token ) => {

    let headers = { 'Content-Type' : 'application/json' }
  
    if( use_api_token && ( [ 'POST', 'PATCH', 'DELETE' ].indexOf( method ) != -1 ) ){

      headers[ 'Authorization' ] = `Token ${ window.api_token }`;
    }
  
    var csrftoken = getCookie( 'csrftoken' );
  
    headers[ 'X-CSRFToken' ] = `${ csrftoken }`;
  
    return headers;
}

export const handleFetchResponse =  response => {

    if ( response.status >= 400 ){

        try{
        
            return response.text()
    
        } catch( e ){
    
            throw new Error( e );
        }
    }

    try{
        
        return response.json();

    } catch( e ){

        throw new Error( e );
    }
}
  
export const handleFetchError = error => {

    throw error;
}
import React from 'react';

import PropTypes from "prop-types";

import { useNavigate } from 'react-router';

const ArticleAssetTrending = ( { id, title, thumbnail, publish_date, excerpt } ) => {

    const navigate = useNavigate();

    const handleOnView = () => {

        navigate(`/news/${ id }`)
    }

    return(
        <div className="rounded-10 hover-border-admin-accent w-100 d-flex overflow-hidden" onClick={ handleOnView } style={{border:'1px solid #ebebeb'}}>
            <div className="col-4 bg-cover" style={ { minHeight:150, backgroundImage: `url(" ${ thumbnail || "" } ")` } }></div>
            <div className="d-flex flex-column flex-grow-1 p-2" style={{gap:6}}>
                <h6 className="bold">{ title || '' }</h6>
                <p className="small">{ excerpt ? `${excerpt.substring( 0, 96 )}...` : '' }</p>
                { publish_date && <h6 className="text-gray medium small">{ new Date( publish_date ).toLocaleDateString( 'en-us', { day:"numeric", month:"short", year:"numeric" } ) }</h6> }
            </div>
        </div>
    )
}

ArticleAssetTrending.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    publish_date: PropTypes.string,
    excerpt: PropTypes.string
}

export default ArticleAssetTrending;

import { handleFetchResponse, handleFetchError, getHeaders } from './../helpers/api';

const PAGE_DETAIL = '/api/pages/<id>/detail';

export const getPageDetail = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PAGE_DETAIL.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}
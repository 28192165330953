import React, {useState} from 'react';

import { NavLink } from 'react-router-dom';

import TimesIcon from '../../global/components/icon/TimesIcon';

import BarsIcon from '../../global/components/icon/BarsIcon';

import UserIcon from '../../global/components/icon/UserIcon';

const MobileNavigation = () => {

    const [open,setOpen] = useState( false )

    return(
        <div className="show-m">
            <button className="h5" onClick={()=>setOpen(true)}>
                <BarsIcon />
            </button>
            <div style={{display: open ? 'flex' : 'none'}} className="z-2 pos-fixed tl-0 cover bg-white align-items-center justify-content-center">
                <div className="pos-abs tr-0 z-3">
                    <button className="p-1 h4" onClick={()=>setOpen(false)}> 
                        <TimesIcon />
                    </button>
                </div>
                <div className="d-flex flex-column gap-20 align-items-center nav" onMouseUp={()=>setOpen(false)}>
                    {/* <NavLink style={{borderBottom:'none'}} end to={'/'} ><img width={60} src={ logo_url } /></NavLink> */}
                    <NavLink end to={'/'} ><h4 className="bold">Home</h4></NavLink>
                    <NavLink to={'/sneakers'} ><h4 className="bold">Sneakers</h4></NavLink>
                    <NavLink to={'/releases'} ><h4 className="bold">Releases</h4></NavLink>
                    <NavLink to={'/news'} ><h4 className="bold">News</h4></NavLink>
                    <NavLink to={'/download'} className='p-1 btn btn-primary'><h4 className="bold">Download</h4></NavLink>
                    <NavLink to={'/account'} ><h4 className="bold"><UserIcon /></h4></NavLink>
                </div>
            </div>
        </div>
    )
}

export default MobileNavigation;
import React, { useEffect, useState } from 'react';

import { getProductsList } from '../../../global/api/products';

import { getReleasesList } from '../../../global/api/releases';

import { useProductsState } from '../../../global/providers/ProductsProvider';

import { useReleasesState } from '../../../global/providers/ReleasesProvider';

import ProductAsset from '../assets/ProductAsset';

import ReleaseAsset from '../assets/ReleaseAsset';

import AbstractScreen from './AbstractScreen';

import ArticleAssetTrending from '../assets/ArticleAssetTrending';

import ArticleAssetFeatured from '../assets/ArticleAssetFeatured';

import { useArticlesState } from '../../../global/providers/ArticlesProvider';

import { getArticlesList } from '../../../global/api/articles';

const HomeScreen = () => {

    const [ productsState, productsDispatch ] = useProductsState();

    const [ releasesState, releasesDispatch ] = useReleasesState();

    const [ articlesState, articlesDispatch ] = useArticlesState();

    const [ featuredNews, setFeaturedNews ] = useState( )

    const [ trendingNews, setTrendingNews ] = useState( )

    const doGetNewReleases = () => {

        getReleasesList( null, 0, 4 ).then( data => {

            if( Array.isArray( data ) ){

                releasesDispatch( { type: "setReleasesList", data } );
            }
        } )
    }

    const doGetNewProducts = () => {

        getProductsList( null, 0, 4 ).then( data => {

            if( Array.isArray( data ) ){

                productsDispatch( { type: "setProductsList", data } );
            }
        } )
    }

    const doGetArticles = () => {

        if( articlesState.articlesList.length == 0 ) {

            getArticlesList().then( data => {
    
                if( Array.isArray( data ) ){
    
                    articlesDispatch( { type: "setArticlesList", data } );
                }
            } )
        }
    }

    useEffect( () => {

        doGetArticles();

        doGetNewProducts();

        doGetNewReleases();

    }, [ ] )

    useEffect( () => {

        if( articlesState.articlesList.length >= 0 ) {

            setFeaturedNews( articlesState.articlesList[ 0 ] )
        }

        if( articlesState.articlesList.length > 1 ) {

            setTrendingNews( [ ...articlesState.articlesList ].slice( 1, 4 ) )
        }

    }, [ articlesState.articlesList ] )

    return(
        <AbstractScreen id={ 'home' }>
            <div className="d-flex flex-column py-4" style={{gap:30}}>
                <div className="d-flex flex-column w-100 gap-10">
                    <h5 className="bold">TRENDING NEWS</h5>
                    <div className="d-flex flex-wrap gap-10 w-100">
                        <div className="col-12 col-d-6">
                            { featuredNews && <ArticleAssetFeatured { ...featuredNews } /> }
                        </div>
                        <div className="flex-grow-1 d-flex flex-column gap-10">
                            { trendingNews && trendingNews.map( article => (
                                <ArticleAssetTrending key={ `featured-article-${ article.id }` } { ...article } />
                            ) ) }
                        </div>
                    </div>
                </div>
                <div className="w-100 d-flex flex-column gap-10">
                    <h5 className="bold">UPCOMING RELEASES</h5>
                    <div className="d-flex gap-10">
                        { releasesState.releasesList.map( release => (
                            <ReleaseAsset key={ `upcoming-release-${ release.id }` } { ...release } style={ { flex:'1 0' } } />
                        ) ) }
                    </div>
                </div>
                <div className="w-100 d-flex flex-column gap-10">
                    <h5 className="bold">TRENDING SNEAKERS</h5>
                    <div className="asset-grid">
                        { productsState.productsList.map( product => (
                            <ProductAsset key={ `trending-product-${ product.id }` } {...product} />
                        ) ) }
                    </div>
                </div>
            </div>
        </AbstractScreen>
    )
}

export default HomeScreen;
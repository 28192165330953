import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

const LoadMoreScrollingModule = ({ children, className, style, onLoadNext }) => {

    const wrapper_el = useRef();

    const content_el = useRef();

    const loadNextTO = useRef();

    const initLoadNext = () => {

        console.log("FUDKCINJS:DLFKNSLDKFJ:LKj")

        if( loadNextTO.current ) clearTimeout( loadNextTO.current )

        loadNextTO.current = setTimeout( onLoadNext, 1000 );
    }

    const onScroll = () => {

        if( content_el.current && wrapper_el.current ){

            if( ( content_el.current.offsetHeight - wrapper_el.current.scrollTop ) <= ( wrapper_el.current.offsetHeight + 50 ) ){

                initLoadNext();
            }
        }
    }

    useEffect( () => {

        window.addEventListener( 'scroll', onScroll, true );

        return () => {

            window.removeEventListener( 'onscroll', onScroll );
        }
    }, [] )

    return(
        <div className={ `${ className } cover overflow-y-auto` } style={ style } ref={ wrapper_el }>
            <div ref={ content_el }>
                { children }    
            </div>
        </div>
    )
}

LoadMoreScrollingModule.propTypes = {
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] ),
    className: PropTypes.string,
    style: PropTypes.object,
    onLoadNext: PropTypes.func.isRequired
}

export default LoadMoreScrollingModule;
import React from 'react';

import PropTypes from "prop-types";

import { stateHandler } from './../helpers/state';

const defaultState = {
    appKey: 'products',
    appLabel: 'Products',
    assetKey: 'product',
    assetLabel: 'Product',
    productsList:[],
    productDetails: {}
}

const ProductsStateContext = React.createContext();

const useProductsState = () => {

    const context = React.useContext( ProductsStateContext );

    if( context === undefined ) throw new Error( "useProductsState must be used within a ProductsProvider" );

    return context
}

const eventHandlers = {
    setProductsList: ( __state, action ) => {

        __state.productsList = [ ...action.data ];
    },
    setProduct: ( __state, action ) => {

        if( !__state.productDetails[ action.data.id ] ) __state.productDetails[ action.data.id ] = {};

        __state.productDetails[ action.data.id ] = { ...action.data };
    },
    updateProduct: ( __state, action ) => {

        if( !__state.productDetails[ action.data.id ] ) __state.productDetails[ action.data.id ] = {};

        __state.productDetails[ action.data.id ] = { ...__state.productDetails[ action.data.id ], ...action.data };
    }
}

const reducer = ( state, action ) => stateHandler( state, action, eventHandlers );

const ProductsProvider = ( { children } ) => {

    const [ state, dispatch ] = React.useReducer(  reducer , defaultState );

    return (
        <ProductsStateContext.Provider value={ [ state, dispatch ] }>
            { children }
        </ProductsStateContext.Provider>
    )
}

ProductsProvider.propTypes = {
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { useProductsState, ProductsProvider };
import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router';

import { getProductsList, searchForProducts } from '../../../global/api/products';

import { Spinner } from '../../../global/components/Spinner';

import { useProductsState } from '../../../global/providers/ProductsProvider';

import ProductAsset from '../assets/ProductAsset';

import AbstractScreen from './AbstractScreen';

import { usePagesState } from '../../../global/providers/PagesProvider';

const ProductsScreen = () => {

    const [ productsState, productsDispatch ] = useProductsState();

    const [ pagesState ] = usePagesState();

    const searchForProductsTO = useRef( false );

    const [ searchingForNewProducts, setSearchingForNewProducts ] = useState( false )

    const [ ready, setReady ] = useState( false )

    const location = useLocation();

    const [ offset, setOffset ] = useState( 0 ) ;

    const limit = 20;

    const [ lastPage, setLastPage ] = useState( false );

    useEffect( () => {

        if( ready && offset != 0 && lastPage == false ){

            let search_str = location.state && location.state.searchStr ? location.state.searchStr : '';

            setReady( false )

            getProductsList( search_str, offset, limit ).then( data => {

                setReady( true );

                let _list = [ ...productsState.productsList, ...data ];

                setOffset( _list.length );

                if( data.length < limit ) setLastPage( true );

                productsDispatch( { type: "setProductsList", data: _list } );
            } )
        }

    }, [ pagesState.loadNextIterations ] )

    useEffect( () => {

        if( searchForProductsTO.current ) clearTimeout( searchForProductsTO.current );

        let search_str = location.state && location.state.searchStr ? location.state.searchStr : '';

        let _list = []

        setOffset( 0 )

        getProductsList( search_str, 0, limit ).then( data => {

            setSearchingForNewProducts( true );

            if( Array.isArray( data ) ){

                _list = [ ...data ]

                setOffset( _list.length )

                productsDispatch( { type: "setProductsList", data: _list } );

                setReady( true )
            }

            searchForProductsTO.current = setTimeout( () => {

                searchForProducts( search_str, limit ).then( data => {
    
                    if( Array.isArray( data ) && data.length > 0 ){
    
                        _list = [ ...data, ..._list ]

                        setOffset( _list.length );

                        if( _list.length < limit ) setLastPage( true );
        
                        productsDispatch( { type: "setProductsList", data: _list } );
                    }
    
                    setSearchingForNewProducts( false );

                    setReady( true )
                } )
            }, 500 )
        } )

    }, [ location.state ] )

    useEffect( () => {

        return () => {

            if( searchForProductsTO.current ) clearTimeout( searchForProductsTO.current );
        }
    }, [] )

    return(
        <AbstractScreen id={ 'products' } className="w-100">
            <div className="d-flex flex-column gap-10">
                <div className="d-flex gap-10">
                    <h5 className="bold">SNEAKERS</h5>
                    { searchingForNewProducts && <div className="pos-abs ml-auto d-flex align-items-center justify-content-center pr-2" style={{ right:0, gap:20 }}>
                        <h6 className="small">Finding new sneakers</h6>
                        <Spinner />
                    </div> }
                </div>
                <div className="asset-grid">
                    { productsState.productsList.map( product => (
                        <ProductAsset key={ `product-${ product.id }` } { ...product } />
                    ) ) }
                </div>
            </div>
        </AbstractScreen>
    )
}

export default ProductsScreen;
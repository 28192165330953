import React from 'react';

import PropTypes from "prop-types";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSearch as I } from '@fortawesome/pro-regular-svg-icons'

import Icon from './Icon';

const SearchIcon = ({ style, className }) => {
    return <Icon style={ style } className={ className }>
        <FontAwesomeIcon icon={ I } />
    </Icon>
}

SearchIcon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

export default SearchIcon
import React from 'react';

import PropTypes from "prop-types";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faAngleRight as I } from '@fortawesome/pro-regular-svg-icons'

import Icon from './Icon';

const AngleRightIcon = ({ style, className }) => {
    return <Icon style={ style } className={ className }>
        <FontAwesomeIcon icon={ I } />
    </Icon>
}

AngleRightIcon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

export default AngleRightIcon
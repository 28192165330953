import React, { useEffect, useRef, useState } from 'react';

import PropTypes from "prop-types";

import { getProductsList, searchForProducts } from '../../global/api/products';

import { Spinner } from '../../global/components/Spinner';

import ProductAsset from './assets/ProductAsset';

const SimilarProductsModule = ( { id, make } ) => {

    const [ productsList, setProductsList ] = useState( [] );

    const searchForProductsTO = useRef( false );

    const [ searchingForNewProducts, setSearchingForNewProducts ] = useState( false )

    useEffect( () => {

        if( searchForProductsTO.current ) clearTimeout( searchForProductsTO.current );

        let _list = [];

        getProductsList( make, 0, 5 ).then( data => {

            setSearchingForNewProducts( true );

            if( Array.isArray( data ) ){

                _list = [ ...data ].filter( _id => _id != id );
            }

            searchForProductsTO.current = setTimeout( () => {
    
                searchForProducts( make, 5 ).then( data => {
    
                    if( Array.isArray( data ) && data.length > 0 ){
    
                        _list = [ ...data, ..._list ].filter( _id => _id != id );
                    }

                    setProductsList( _list )
    
                    setSearchingForNewProducts( false );
                } )
            }, 1000 )
        } )

        return () => {

            if( searchForProductsTO.current ) clearTimeout( searchForProductsTO.current );
        }
    }, [] )

    return(
        <div className="w-100 pos-rel pb-3">
            <div className="d-flex py-1" style={ { gap: 8 } }>
                <h5 className="bold">SIMILAR STYLES</h5>
                { searchingForNewProducts && <div className="pos-abs ml-auto d-flex align-items-center justify-content-center pr-2" style={{ right: 0, gap: 20, borderRadius: 20 }}>
                    <h6 className="small">Finding similar sneakers</h6>
                    <Spinner />
                </div> }
            </div>
            <div className="asset-grid">
                { productsList.map( product => (
                    <ProductAsset key={ `product-${ product.id }` } { ...product } />
                ) ) }
            </div>
        </div>
    )
}

SimilarProductsModule.propTypes = {
    id: PropTypes.number.isRequired,
    make: PropTypes.string.isRequired
};

export default SimilarProductsModule;
import ArticleSectionLeft from "./components/article-sections/ArticleSectionLeft";
import ArticleSectionRight from "./components/article-sections/ArticleSectionRight";
import ArticleSectionTop from "./components/article-sections/ArticleSectionTop";
import ArticleSectionBottom from "./components/article-sections/ArticleSectionBottom";

export const section_lookup = {
    'left': ArticleSectionLeft,
    'right': ArticleSectionRight,
    'top': ArticleSectionTop,
    'bottom': ArticleSectionBottom
}
import React from 'react';

import PropTypes from "prop-types";

import ArticleImage from './ArticleImage';

const ArticleSectionTop = ( { images, body } ) => {

    return(
        <div className="d-flex flex-column w-100 gap-30">
            { body && body.length > 0 && <div dangerouslySetInnerHTML={ { __html: body } }></div> }
            { ( images && images.length > 0 ) && <div className="d-flex flex-wrap gap-10">
                { images.map( image => <ArticleImage key={`article-image-${ image.id }`} { ...image } /> ) }
            </div> }
        </div>
    )
}

ArticleSectionTop.propTypes = {
    images: PropTypes.array,
    body: PropTypes.string
};

export default ArticleSectionTop;
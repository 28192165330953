
import { handleFetchResponse, handleFetchError, getHeaders } from './../helpers/api';

const PRODUCT_LIST = '/api/products/list?search_str=<search_str>&offset=<offset>&limit=<limit>';

const PRODUCT_SEARCH = '/api/products/search?search_str=<search_str>&limit=<limit>';

const PRODUCT_ASSET = '/api/products/<id>/asset';

const PRODUCT_DETAIL = '/api/products/<id>/detail';

const PRODUCT_ASKS = '/api/products/<id>/asks';

const PRODUCT_LINKS = '/api/products/<id>/links';

export const searchForProducts = ( search_str, limit ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PRODUCT_SEARCH.replace( "<search_str>", search_str || '' ).replace( "<limit>", limit || 40 ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const getProductsList = ( search_str, offset, limit ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PRODUCT_LIST.replace( "<search_str>", search_str || '' ).replace( "<offset>", offset || 0 ).replace( "<limit>", limit || 40 ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const getProductLinks = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PRODUCT_LINKS.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const getProductAsks = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PRODUCT_ASKS.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}
  
export const getProductAsset = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PRODUCT_ASSET.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const getProductDetail = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( PRODUCT_DETAIL.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const postProduct = ( title ) => {

    let method = 'POST', headers = getHeaders( method, false ),

    body = JSON.stringify( { title } );

    return fetch( PRODUCT_LIST, { method, headers, body } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const patchProduct = ( id, data ) => {

    let method = 'PATCH', headers = getHeaders( method, false ),

    body = JSON.stringify( data );

    return fetch( PRODUCT_DETAIL.replace( "<id>", id ), { method, headers, body } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const deleteProduct = ( id ) => {

    let method = 'DELETE', headers = getHeaders( method, false );

    return fetch( PRODUCT_DETAIL.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

import React from 'react';

import PropTypes from "prop-types";

import { stateHandler } from './../helpers/state';

const defaultState = {
    appKey: 'articles',
    appLabel: 'Articles',
    assetKey: 'article',
    assetLabel: 'Article',
    articlesList:[],
    articleDetails: {},
    loadNextIterations: 0
}

const ArticlesStateContext = React.createContext();

const useArticlesState = () => {

    const context = React.useContext( ArticlesStateContext );

    if( context === undefined ) throw new Error('useArticlesState must be used within a ArticlesProvider');

    return context
}

const eventHandlers = {
    setArticlesList: ( __state, action ) => {

        __state.articlesList = [ ...action.data ];
    },
    setArticle: ( __state, action ) => {

        if( !__state.articleDetails[ action.data.id ] ) __state.articleDetails[ action.data.id ] = {};

        __state.articleDetails[ action.data.id ] = { ...action.data };
    },
    updateArticle: ( __state, action ) => {

        if( !__state.articleDetails[ action.data.id ] ) __state.articleDetails[ action.data.id ] = {};

        __state.articleDetails[ action.data.id ] = { ...__state.articleDetails[ action.data.id ], ...action.data };
    }
}

const reducer = ( state, action ) => stateHandler( state, action, eventHandlers );

const ArticlesProvider = ( { children } ) => {

    const [ state, dispatch ] = React.useReducer(  reducer , defaultState );

    return (
        <ArticlesStateContext.Provider value={ [ state, dispatch ] }>
            { children }
        </ArticlesStateContext.Provider>
    )
}

ArticlesProvider.propTypes = {
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { useArticlesState, ArticlesProvider };
import React, { useState, useEffect } from 'react';

import { NavLink, useParams } from 'react-router-dom';

import { getReleaseDetail } from '../../../global/api/releases';

import { useReleasesState } from '../../../global/providers/ReleasesProvider';

import SimilarReleasesModule from '../SimilarReleasesModule';

import AbstractScreen from './AbstractScreen';

const ReleaseDetailScreen = () => {

    const { releaseId } = useParams();

    const [ ready, setReady ] = useState( false );

    const [ state, setState ] = useState( {} );

    const [ releasesState, releasesDispatch ] = useReleasesState();

    useEffect( () => {

        setReady( false );

        getReleaseDetail( releaseId ).then( data => {

            releasesDispatch( { type: 'setRelease', data } );

            setReady( true );
        } )

    }, [ releaseId ] )

    useEffect( () => {

        if( releasesState.releaseDetails[ releaseId ] ){

            setState( { ...releasesState.releaseDetails[ releaseId ] } )
        }
    }, [ releasesState.releaseDetails[ releaseId ] ] )

    return (
        <AbstractScreen id={ 'product-detail' }>
            { ready && <div>
                <div className="d-flex pb-1 pos-rel w-100">
                    <div className="pos-rel d-flex text-dark-gray gap-10">
                        <NavLink to="/releases"><h6 className="small">releases</h6></NavLink><span className="text-gray">|</span><h6 className="small">{ state.slug }</h6>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-start" style={ { gap:30 } }>
                    <div className="col-12 col-d-7 rounded-10 overflow-hidden" style={{border:'1px solid #ebebeb'}}>
                        <div className="bg-white px-2">
                            <h4 className="bold py-2">{ state.title }</h4>
                            { state.image && <div className="d-flex w-100"><img style={{maxWidth:600, margin:'auto'}} src={ state.image } width={'100%'} /></div> }
                        </div>
                        <div style={{borderTop:'1px solid #ebebeb'}}>
                            <div className="d-flex flex-wrap w-100 pt-1 pb-2">
                                <div className="col-12 col-d-7 pt-1 px-2 d-flex flex-column" style={{gap:10}}>
                                    { state.colorway && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Colorway</h6><h6 className="small ml-auto medium text-right">{state.colorway}</h6></div> }
                                    { state.sku && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Style</h6><h6 className="small ml-auto medium text-right">{state.sku}</h6></div> }
                                    { state.make && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Make</h6><h6 className="small ml-auto medium text-right">{state.make}</h6></div> }
                                </div>
                                <div className="col-12 col-d-5 pt-1 px-2 d-flex flex-column" style={{gap:10, borderLeft:'1px solid #ebebeb'}}>
                                    { state.release_date && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Release Date</h6><h6 className="small ml-auto medium text-right">{ new Date( state.release_date ).toLocaleDateString( 'en-us', { day:"numeric", month:"short", year:"numeric" } ) }</h6></div> }
                                    { state.retail_price && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Retail Price</h6><h6 className="small ml-auto medium text-right">${state.retail_price}</h6></div> }
                                    { state.brand && <div className="d-flex" style={{gap:40}}><h6 className="small text-dark-gray">Brand</h6><h6 className="small ml-auto medium text-right">{ state.brand }</h6></div> }
                                </div>
                            </div>
                            { state.description && <div className="px-2 pt-4 pb-2 text-dark-gray small" dangerouslySetInnerHTML={{__html:state.description}}></div> }
                        </div>
                    </div>
                    <div className="flex-grow-1 pos-rel d-flex flex-column gap-20">
                        <div className="d-flex flex-column" style={{ gap:20 }}>
                            <div className="d-flex flex-column" style={{ gap:20 }}>
                                <div className="d-flex flex-column gap-10">
                                    <h5 className="bold lh-1">WHERE TO BUY</h5>
                                    { state.links && <div style={{border:'1px solid #ebebeb'}} className="bg-white rounded-10 cover align-items-start p-1">
                                        { state.links.map( ( link ) => (
                                            <div style={{padding:'10px 10px'}} className="w-100 d-flex align-items-center site-price gap-10" key={ `link-${ link.id }` } >
                                                <h6 className="small medium upper lh-1">{ link.title }</h6>
                                                <p className="ml-auto text-gray m-0 lh-1">{ link.release_date }</p>
                                                <div className="text-go bold lh-1"><a rel="noreferrer" target="_blank" href={ link.url } className="bold rounded-8 bg-go small d-block" style={ { padding:'8px 20px'  } }>VISIT</a></div>
                                            </div>
                                        ) ) }
                                    </div> }
                                </div>
                                <p className="w-100 text-gray tiny">Sneaker Seeker may receive a share of the sale if you click and purchase through one of our links on any of the posts throughout the website.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 pt-2">
                        { state.id && state.make && <SimilarReleasesModule id={ state.id } make={ state.make } /> }
                    </div>
                </div>
            </div> }
        </AbstractScreen>
    )
}

export default ReleaseDetailScreen;
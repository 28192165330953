import React from 'react';

import PropTypes from "prop-types";

import SSLogo from '../../../global/components/SSLogo';

import { useNavigate } from 'react-router';

const ProductAsset = ( { id, title, thumbnail, retail_price } ) => {

    const navigate = useNavigate();

    const handleOnView = () => {

        navigate(`/sneakers/${ id }`)
    }

    return(
        <div className="rounded-10 asset product-asset hover-border-admin-accent d-flex flex-column" onClick={ handleOnView }>
            <div className="pos-rel py-1">
                <div className="bg-contain d-flex align-items-center justify-content-center w-100 text-light-gray" style={ { aspectRatio: "16 / 9", backgroundImage: `url(" ${ thumbnail || "" } ")` } }>
                    { !thumbnail && <div><SSLogo width={ 60 } /></div> }
                </div>
            </div>
            <div className={`p-1 d-flex flex-column flex-grow-1`} style={{borderTop:'1px solid #ebebeb', gap:10 }}>
                <h6 className="bold">{ title || '' }</h6>
                <div className="d-flex mt-auto" style={{gap:20}}>
                    <div className="d-flex align-items-end" style={{gap:6}} >
                        <h5 className="text-black bold lh-1 mb-0">{ `$${ retail_price || ''}` }</h5>
                        <h6 className="text-gray small lh-1 mb-0">Retail</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductAsset.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    retail_price: PropTypes.string,

}

export default ProductAsset;

import React from 'react';

import PropTypes from 'prop-types';

const SSLogo = ( { width } ) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width || 25 } viewBox="0 0 25 32">
            <path fill="currentcolor" d="M24.37,26.19l-.09-.15c-1.79-2.91-3.48-5.65-4.31-8.63-.22-.79-.49-1.73-.79-2.67-.38-1.16-.25-1.45-.1-1.78,.27-.61,.48-1.25,.05-2.45-.1-.28-.23-.54-.32-.7-.19-.34,.55-1.41,.94-1.98,.69-1,1.4-2.04,.94-3.08-.28-.64-.93-1.04-1.91-1.19-.66-.1-1.24-.22-1.78-.38-3.19-.93-4.15-1.88-4.67-2.39-.36-.35-.96-.94-1.91-.63-.49,.16-.92,.5-1.96,1.33-.61,.48-1.38,1.1-2.45,1.91,.23-.02,.46-.03,.69-.03,.85,0,1.68,.13,2.46,.39,.26-.21,.5-.4,.7-.56,.37-.3,.73-.58,.98-.77,.68,.66,1.95,1.81,5.52,2.86,.64,.19,1.32,.33,2.08,.45,0,0,0,0,.01,0-.15,.26-.38,.6-.55,.85-.8,1.16-1.89,2.74-1.06,4.26,.09,.16,.14,.28,.18,.38,.17,.49,.14,.56,.01,.84-.32,.73-.56,1.53,.02,3.34,.29,.9,.55,1.81,.77,2.58,.91,3.27,2.68,6.15,4.56,9.19l.09,.15c.06,.1,.09,.22,.08,.34-.1,1.23-.4,1.77-.65,1.87-.11,.04-.77,.19-2.6-1.09-1.33-.93-2.8-2.27-4.2-3.56-2.06-1.91-4.14-3.92-6.19-5.97h0c-.12-.12-.23-.23-.35-.35-.61,.15-1.24,.22-1.88,.22-.39,0-.78-.03-1.16-.08,.1,.09,.19,.18,.29,.28,.48,.46,.99,.95,1.51,1.48,2.07,2.07,4.17,4.1,6.25,6.03,3.11,2.88,5.9,5.28,8.13,5.28,.34,0,.68-.06,.99-.18,1.21-.46,1.9-1.72,2.07-3.75,.05-.59-.09-1.16-.4-1.66Z"/>
            <path fill="currentcolor" d="M9.84,14.2c-.87,.86-2.01,1.29-3.16,1.29-.57,0-1.14-.11-1.68-.32-.54-.21-1.04-.54-1.47-.96-.61-.61-1.01-1.35-1.19-2.13-.29-1.25-.02-2.61,.81-3.67,.12-.15,.24-.29,.38-.43,.34-.33,.71-.6,1.12-.8,.64-.32,1.34-.48,2.04-.48,1.14,0,2.29,.43,3.16,1.29,1.74,1.72,1.74,4.51,0,6.23Zm6.39,4.75l-4.09-4.04c1.86-2.58,1.62-6.19-.72-8.5-.95-.94-2.13-1.54-3.36-1.79-.45-.09-.91-.14-1.37-.14-1.12,0-2.24,.28-3.25,.83-.53,.29-1.03,.66-1.48,1.1-.14,.14-.28,.29-.41,.44h0C.1,8.56-.33,10.83,.25,12.88c.3,1.05,.87,2.05,1.71,2.88,.53,.53,1.14,.95,1.78,1.26,.93,.45,1.94,.68,2.95,.68,.31,0,.61-.02,.92-.06,1.05-.14,2.07-.53,2.96-1.16l4.09,4.04c.11,.11,.25,.16,.39,.16s.29-.05,.39-.16l.79-.78c.22-.21,.22-.56,0-.78Z"/>
        </svg>
    )
}

SSLogo.propTypes = {
    width: PropTypes.number
}

export default SSLogo
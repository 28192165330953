import React from 'react';

import PropTypes from 'prop-types';

import AbstractScreen from './AbstractScreen';

const DownloadScreen = ( { image_location } ) => {

    return(
        <AbstractScreen id={ 'download' } className="w-100">
            <div className="w-100 d-flex flex-wrap align-items-center flex-column pb-4">
                <div className="w-100 d-flex flex-wrap align-items-center" style={{borderBottom:'1px solid #ebebeb'}}>
                    <div className="col-12 col-d-6 px-3">
                        <div className="d-flex flex-column gap-10 align-items-start ml-auto" style={ { maxWidth: 460 } }>
                            <h2 className="bold">Sneaker&nbsp;Seeker</h2>
                            <p className="text-dark-gray" style={{maxWidth:400}}>The only sneaker app you will ever need, completely FREE of charge. Keep up to date with current sneaker releases, news and updates effortlessly while shopping your grails for the best possible prices. Past, present and future releases right at your finger&nbsp;tips.</p>
                            <div className="pt-1">
                                <a href="https://apps.apple.com/us/app/sneaker-seeker/id1546537724" target="_blank" rel="noreferrer">
                                    <img src={`${ image_location }download_images/download-appstore-b478c6c8ce65fba798785cfc2b4d167d.svg`} style={{ maxWidth: 460, width:'100%' }} alt="Download on the App Store" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-d-6 lh-0 pt-2">
                        <img src={`${ image_location }download_images/phone1-1e4f27dcfef0f0ebcc7714eeacad455a.png`} style={{ maxWidth: 460, width:'100%' }} alt="Sneaker Seeker App" />
                    </div>
                </div>
                <div className="w-100 d-flex flex-wrap align-items-center">
                    <div className="col-12 col-d-6 pt-3 text-right">
                        <img src={`${ image_location }download_images/explorephone-9244f8ff64be57ee72e49983bb48de23.png`} style={{ maxWidth: 460, width:'100%' }} alt="Sneaker Seeker App" />
                    </div>
                    <div className="col-12 col-d-6 px-3">
                        <div className="d-flex flex-column gap-10 align-items-start" style={ { maxWidth: 460 } }>
                            <h5 className="upper text-gray medium">Explore</h5>
                            <h3 className="bold">Be in the&nbsp;know</h3>
                            <p className="text-dark-gray" style={{maxWidth:400}}>Capitalize on market knowledge through real time pricing&nbsp;updates.</p>
                            <a href="https://apps.apple.com/us/app/sneaker-seeker/id1546537724" target="_blank" rel="noreferrer" className="btn btn-primary p-1 mt-1">Download&nbsp;Now</a>
                        </div>
                    </div>
                </div>
                <div className="w-100 d-flex flex-wrap align-items-center">
                    <div className="col-12 col-d-6 px-3">
                        <div className="d-flex flex-column gap-10 align-items-start ml-auto" style={ { maxWidth: 460 } }>
                            <h5 className="upper text-gray medium">COMPARE&nbsp;PRICES</h5>
                            <h3 className="bold">Buy shoes at the best&nbsp;price</h3>
                            <p className="text-dark-gray" style={{maxWidth:400}}>Side by side price comparisons from the best sneaker resell platforms enabling you to get more for&nbsp;less!</p>
                            <a href="https://apps.apple.com/us/app/sneaker-seeker/id1546537724" target="_blank" rel="noreferrer" className="btn btn-primary p-1 mt-1">Download&nbsp;Now</a>
                        </div>
                    </div>
                    <div className="col-12 col-d-6">
                        <img src={`${ image_location }download_images/marketphone-780c74892e19cdb8fcab168d44ee7998.png`} style={{ maxWidth: 460, width:'100%' }} alt="Sneaker Seeker App" />
                    </div>
                </div>
                <div className="w-100 d-flex flex-wrap align-items-center">
                    <div className="col-12 col-d-6 text-right">
                        <img src={`${ image_location }download_images/releasephone-64bbf023b44cbe66e3ed8f9ed4cf1c3e.png`} style={{ maxWidth: 460, width:'100%' }} alt="Sneaker Seeker App" />
                    </div>
                    <div className="col-12 col-d-6 px-3">
                        <div className="d-flex flex-column gap-10 align-items-start" style={ { maxWidth: 460 } }>
                            <h5 className="upper text-gray medium">Notifcations</h5>
                            <h3 className="bold">Be&nbsp;Notified</h3>
                            <p className="text-dark-gray" style={{maxWidth:400}}>Fast pings, early alerts and customizable reminders to keep you ahead of the&nbsp;rest.</p>
                            <a href="https://apps.apple.com/us/app/sneaker-seeker/id1546537724" target="_blank" rel="noreferrer" className="btn btn-primary p-1 mt-1">Download&nbsp;Now</a>
                        </div>
                    </div>
                </div>
                <div className="w-100 d-flex flex-wrap align-items-center">
                    <div className="col-12 col-d-6 px-3">
                        <div className="d-flex flex-column gap-10 align-items-start ml-auto" style={ { maxWidth: 460 } }>
                            <h5 className="upper text-gray medium">Site&nbsp;lists</h5>
                            <h3 className="bold">Details&nbsp;Matter</h3>
                            <p className="text-dark-gray" style={{maxWidth:400}}>Learn about your favorite upcoming silhouettes through descriptions, style codes and&nbsp;sitelists.</p>
                            <a href="https://apps.apple.com/us/app/sneaker-seeker/id1546537724" target="_blank" rel="noreferrer" className="btn btn-primary p-1 mt-1">Download&nbsp;Now</a>
                        </div>
                    </div>
                    <div className="col-12 col-d-6">
                        <img src={`${ image_location }download_images/sitelistphone-7487e034138230db6185b7b401c7327b.png`} style={{ maxWidth: 460, width:'100%' }} alt="Sneaker Seeker App" />
                    </div>
                </div>
                <div className="w-100 d-flex flex-wrap align-items-center">
                    <div className="col-12 col-d-6 text-right">
                        <img src={`${ image_location }download_images/newsphone-16612f7965265c6208eb230f9d0604a6.png`} style={{ maxWidth: 460, width:'100%' }} alt="Sneaker Seeker App" />
                    </div>
                    <div className="col-12 col-d-6 px-3">
                        <div className="d-flex flex-column gap-10 align-items-start" style={ { maxWidth: 460 } }>
                            <h5 className="upper text-gray medium">News</h5>
                            <h3 className="bold">Hot&nbsp;Takes</h3>
                            <p className="text-dark-gray" style={{maxWidth:400}}>Read news articles engineered by the most dedicated of the sneaker&nbsp;game.</p>
                            <a href="https://apps.apple.com/us/app/sneaker-seeker/id1546537724" target="_blank" rel="noreferrer" className="btn btn-primary p-1 mt-1">Download&nbsp;Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </AbstractScreen>
    )
}

DownloadScreen.propTypes = {
    image_location: PropTypes.string
};

export default DownloadScreen;
import React, {useEffect} from 'react';

import PropTypes from "prop-types";

import { stateHandler } from './../helpers/state';

const defaultState = {
    appKey: 'pages',
    appLabel: 'Pages',
    assetKey: 'page',
    assetLabel: 'Page',
    pagesList:[],
    pageDetails: {},
    loadNextIterations: 0
}

const PagesStateContext = React.createContext();

const usePagesState = () => {

    const context = React.useContext( PagesStateContext );

    if( context === undefined ) throw new Error('usePagesState must be used within a PagesProvider');

    return context
}

const eventHandlers = {
    setPagesList: ( __state, action ) => {

        __state.pagesList = [ ...action.data ];
    },
    updatePage: ( __state, action ) => {

        if( !__state.pageDetails[ action.data.id ] ) __state.pageDetails[ action.data.id ] = {};

        __state.pageDetails[ action.data.id ] = { ...__state.pageDetails[ action.data.id ], ...action.data };
    },
    setLoadNextIterations: ( __state, action ) => {

        __state.loadNextIterations = action.loadNextIterations;
    }
}

const reducer = ( state, action ) => stateHandler( state, action, eventHandlers );

const PagesProvider = ( { children, loadNextIterations } ) => {

    const [ state, dispatch ] = React.useReducer(  reducer , defaultState );

    useEffect( () => {

        dispatch( { type:'setLoadNextIterations', loadNextIterations })
        
    }, [ loadNextIterations ] )

    return (
        <PagesStateContext.Provider value={ [ state, dispatch ] }>
            { children }
        </PagesStateContext.Provider>
    )
}

PagesProvider.propTypes = {
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] ),
    loadNextIterations: PropTypes.number.isRequired
}

export { usePagesState, PagesProvider };
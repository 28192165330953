import React from "react";

import { render } from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";

import Web from "./web/Web";

import './../scss/web.scss';

import { WindowProvider } from "./web/providers/WindowProvider";

render(
  <Router>
    <WindowProvider>
      <Web { ...window.props } />
    </WindowProvider>
  </Router>,
  document.getElementById('root') 
);
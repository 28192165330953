import React from 'react';

import PropTypes from "prop-types";

const ArticleImage = ( { id, width, link, image, caption, aspectRatio } ) => {

    return(
        <a key={`section-image-${ id }`} className="flex-grow-1-m lh-0 d-block" style={ { flex:`1 1 ${ width || '100%'}` } } href={ link } target="_blank" rel="noreferrer">
            <div className="rounded-10 overflow-hidden" style={ { border:'1px solid #ebebeb' } }>
                <div className='w-100 bg-cover' style={ { backgroundImage:`url( ${ image } )`, aspectRatio: aspectRatio || '16 / 9'}} />
                { caption && <p style={ { padding:'18px 14px' } } className="tiny text-gray">{ caption }</p> }
            </div>
        </a>
    )
}

ArticleImage.propTypes = {
    id: PropTypes.number.isRequired,
    width: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string,
    caption: PropTypes.string,
    aspectRatio: PropTypes.string
};

export default ArticleImage;
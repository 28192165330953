import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";

import { getReleasesList } from '../../global/api/releases';

import ReleaseAsset from './assets/ReleaseAsset';

import { Spinner } from '../../global/components/Spinner';

const SimilarReleasesModule = ( { id, make } ) => {

    const [ releasesList, setReleasesList ] = useState( [] );

    const [ searchingForReleases, setSearchingForReleases ] = useState( false )

    useEffect( () => {

        let _list = [];

        setSearchingForReleases( true );

        getReleasesList( make, 0, 7 ).then( data => {

            if( Array.isArray( data ) ){

                _list = [ ...data ].filter( _id => _id != id );

                setReleasesList( _list );
            }

            setSearchingForReleases( false );
        } )
    }, [] )

    return(
        <div className="w-100 pos-rel pb-3">
            <div className="d-flex py-1" style={ { gap: 8 } }>
                <h5 className="bold">UPCOMING RELEASES</h5>
                { searchingForReleases && <div className="pos-abs ml-auto d-flex align-items-center justify-content-center pr-2" style={{ right: 0, gap: 20, borderRadius: 20 }}>
                    <h6 className="small">Finding similar sneakers</h6>
                    <Spinner />
                </div> }
            </div>
            <div className="asset-grid">
                { releasesList.map( release => (
                    <ReleaseAsset key={ `release-${ release.id }` } { ...release } />
                ) ) }
            </div>
        </div>
    )
}

SimilarReleasesModule.propTypes = {
    id: PropTypes.number.isRequired,
    make: PropTypes.string.isRequired
};

export default SimilarReleasesModule;
import React from 'react';

import PropTypes from "prop-types";

import { useNavigate } from 'react-router';

const ArticleAsset = ( { id, title, thumbnail, publish_date, excerpt } ) => {

    const navigate = useNavigate();

    const handleOnView = () => {

        navigate( `/news/${ id }` )
    }

    return(
        <div className="rounded-10 asset article-asset hover-border-admin-accent overflow-hidden" onClick={ handleOnView }>
            <div className="bg-cover d-flex align-items-center justify-content-center w-100 text-light-gray" style={ { aspectRatio: "16 / 9", backgroundImage: `url(" ${ thumbnail || "" } ")` } }></div>
            <div className="d-flex flex-column flex-grow-1 p-1" style={ { gap: 6 } }>
                <h6 className="bold">{ title || '' }</h6>
                <p className="small">{ excerpt ? `${ excerpt.substring( 0, 48 ) }...` : '' }</p>
                { publish_date && <h6 className="text-gray medium small">{ new Date( publish_date ).toLocaleDateString( 'en-us', { day:"numeric", month:"short", year:"numeric" } ) }</h6> }
            </div>
        </div>
    )
}

ArticleAsset.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    publish_date: PropTypes.string,
    excerpt: PropTypes.string
}

export default ArticleAsset;
import React from 'react';

import PropTypes from "prop-types";

import { stateHandler } from './../helpers/state';

const defaultState = {
    appKey: 'releases',
    appLabel: 'Releases',
    assetKey: 'release',
    assetLabel: 'Release',
    releasesList: [],
    releaseDetails: {}
}

const ReleasesStateContext = React.createContext();

const useReleasesState = () => {

    const context = React.useContext( ReleasesStateContext );

    if( context === undefined ) throw new Error('useReleasesState must be used within a ReleasesProvider');

    return context
}

const eventHandlers = {
    setReleasesList: ( __state, action ) => {

        __state.releasesList = [ ...action.data ];
    },
    setRelease: ( __state, action ) => {

        if( !__state.releaseDetails[ action.data.id ] ) __state.releaseDetails[ action.data.id ] = {};

        __state.releaseDetails[ action.data.id ] = { ...action.data };
    },
    updateRelease: ( __state, action ) => {

        if( !__state.releaseDetails[ action.data.id ] ) __state.releaseDetails[ action.data.id ] = {};

        __state.releaseDetails[ action.data.id ] = { ...__state.releaseDetails[ action.data.id ], ...action.data };
    }
}

const reducer = ( state, action ) => stateHandler( state, action, eventHandlers );

const ReleasesProvider = ( { children } ) => {

    const [ state, dispatch ] = React.useReducer(  reducer , defaultState );

    return (
        <ReleasesStateContext.Provider value={ [ state, dispatch ] }>
            { children }
        </ReleasesStateContext.Provider>
    )
}

ReleasesProvider.propTypes = {
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { useReleasesState, ReleasesProvider };
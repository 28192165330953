
import { handleFetchResponse, handleFetchError, getHeaders } from './../helpers/api';

const RELEASE_LIST = '/api/releases/list?search_str=<search_str>&offset=<offset>&limit=<limit>';

const RELEASE_ASSET = '/api/releases/<id>/asset';

const RELEASE_DETAIL = '/api/releases/<id>/detail';

export const getReleasesList = ( search_str, offset, limit ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( RELEASE_LIST.replace( "<search_str>", search_str || '' ).replace( "<offset>", offset || 0 ).replace( "<limit>", limit || 40 ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const getReleaseAsset = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( RELEASE_ASSET.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const getReleaseDetail = ( id ) => {

    let method = 'GET', headers = getHeaders( method, false );

    return fetch( RELEASE_DETAIL.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const postRelease = ( title ) => {

    let method = 'POST', headers = getHeaders( method, false ),

    body = JSON.stringify( { title } );

    return fetch( RELEASE_LIST, { method, headers, body } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const patchRelease = ( id, data ) => {

    let method = 'PATCH', headers = getHeaders( method, false ),

    body = JSON.stringify( data );

    return fetch( RELEASE_DETAIL.replace( "<id>", id ), { method, headers, body } )
    .then( handleFetchResponse ).catch( handleFetchError );
}

export const deleteRelease = ( id ) => {

    let method = 'DELETE', headers = getHeaders( method, false );

    return fetch( RELEASE_DETAIL.replace( "<id>", id ), { method, headers } )
    .then( handleFetchResponse ).catch( handleFetchError );
}
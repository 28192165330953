import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";

import { getArticlesList } from '../../global/api/articles';

import ArticleAsset from './assets/ArticleAsset';

import { Spinner } from '../../global/components/Spinner';

const SimilarArticlesModule = ( { id, keyword, next } ) => {

    const [ articlesList, setArticlesList ] = useState();

    const [ searchingForArticles, setSearchingForArticles ] = useState( false )

    useEffect( () => {

        if( keyword ){

            let _list = [];
    
            setSearchingForArticles( true );
    
            getArticlesList( keyword, 0, 7 ).then( data => {
    
                if( Array.isArray( data ) ){
    
                    _list = [ ...data ].filter( article => article.id != id && article.id != next.id );
    
                    setArticlesList( _list.length > 0 ? _list : null );
                }
    
                setSearchingForArticles( false );
            } )
        }
    }, [ keyword ] )

    return(
        <div className="w-100 pos-rel pb-3 d-flex flex-column" style={ { gap: 20 } }>
            { next && <div className="d-flex flex-column" style={ { gap: 10 } }>
                <h5 className="bold">READ NEXT</h5>
                <ArticleAsset { ...next } />
            </div> }
            { keyword && articlesList && <div className="d-flex flex-column" style={ { gap: 10 } }>
                <div className="d-flex" style={ { gap: 8 } }>
                    <h5 className="bold">MORE LIKE THIS</h5>
                    { searchingForArticles && <div className="pos-abs ml-auto d-flex align-items-center justify-content-center" style={{ right: 0, gap: 20, borderRadius: 20 }}>
                        <h6 className="small">Finding similar articles</h6>
                        <Spinner />
                    </div> }
                </div>
                <div className="d-fle flex-column w-100" style={ { gap: 8 } }>
                    { articlesList.map( article => (
                        <ArticleAsset key={ `article-${ article.id }` } { ...article } />
                    ) ) }
                </div>
            </div> }
        </div>
    )
}

SimilarArticlesModule.propTypes = {
    id: PropTypes.number.isRequired,
    keyword: PropTypes.string,
    next: PropTypes.object
};

export default SimilarArticlesModule;
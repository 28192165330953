import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";

import { usePagesState } from '../../../global/providers/PagesProvider';

import { getPageDetail } from '../../../global/api/pages';

import PageBanner from '../PageBanner';

const AbstractScreen = ( { id, children, className } ) => {

    const [ pagesState, pagesDispatch ] = usePagesState();
    
    const [ state, setState ] = useState( {} );

    useEffect( () => {

        if( !pagesState.pageDetails[ id ] ){

            getPageDetail( id ).then( data => {

                pagesDispatch( { type:'updatePage', data: { ...data, id } } );
            } );
        }
    }, [ ] )

    useEffect( () => {

        setState( { ...pagesState.pageDetails[ id ] } );

    }, [ pagesState.pageDetails[ id ] ] )

    return(
        <div className={`screen-${ id } pos-rel screen no-select w-100 py-2 d-flex flex-column px-2 px-t-1 ${ className }`} style={{maxWidth:1280, gap:20}}>
            { state.banner && <PageBanner sections={ state.banner.sections } /> }
            { children }
        </div>
    )
}

AbstractScreen.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string
}

export default AbstractScreen;
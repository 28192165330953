import React, { useState, useEffect, useRef } from 'react';

import PropTypes from "prop-types";

import { NavLink, useNavigate } from 'react-router-dom';

import MobileNavigation from './MobileNavigation';

import SearchIcon from '../../global/components/icon/SearchIcon';

const HeaderNavigation = ( { logo_url } ) => {

    const [ searchStr, setSearchStr ] = useState( "" );

    const searchTO = useRef();

    const navigate = useNavigate();

    useEffect( () => {

        if( searchStr ){

            if( searchTO.current ) clearTimeout( searchTO.current );

            searchTO.current = setTimeout( () => {

                navigate( "/sneakers", { state: { searchStr } } );

            }, 1000 )
        }
        
    },[ searchStr ] )

    return(
        <div className="py-1 bg-white" style={{borderBottom:'1px solid #ebebeb'}}>
            <div className="d-flex gap-20 align-items-center bg-white mx-auto px-2" style={{maxWidth:1290}}>
                <a href="/" className="d-flex gap-10 align-items-center ">
                    <img src={ logo_url } />
                    <h5 className="upper bold hide-m">Sneaker Seeker</h5>
                </a>
                <div className="mx-auto flex-grow-1 pos-rel" style={{ maxWidth:600 }} >
                    <SearchIcon className={'pos-abs tl-0 '} style={{padding:10, marginLeft:3}} />
                    <input value={ searchStr } onChange={ e => setSearchStr( e.target.value ) } style={{ padding:'8px 18px 8px 40px' }} className="w-100"  type="text" placeholder="Search for sneakers" />
                </div>
                <MobileNavigation logo_url={ logo_url } />
                <div className="d-flex gap-20 align-items-center nav hide-m small">
                    <NavLink  className="bold" end to={'/'} >Home</NavLink>
                    <NavLink  className="bold" to={'/sneakers'} >Sneakers</NavLink>
                    <NavLink  className="bold" to={'/releases'} >Releases</NavLink>
                    <NavLink  className="bold" to={'/news'} >News</NavLink>
                    <NavLink  className='p-1 btn btn-primary lh-0' to={'/download'}>Download</NavLink>
                </div>
            </div>
        </div>
    )
}

HeaderNavigation.propTypes = {
    logo_url: PropTypes.string.isRequired
};

export default HeaderNavigation;
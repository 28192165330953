import React from 'react';

import PropTypes from "prop-types";

import ArticleImage from './ArticleImage';

const ArticleSectionLeft = ( { images, body } ) => {
    return(
        <div className="w-100 d-flex flex-wrap gap-30 align-items-center">
            { body && <div className="col-12 col-d-6" dangerouslySetInnerHTML={{__html: body}}></div> }
            { ( images && images.length > 0 ) && <div className="flex-grow-1 d-flex flex-wrap gap-10">
                { images.map( image => <ArticleImage key={`article-image-${ image.id }`} { ...image } /> ) }
            </div> }
        </div>
    )
}

ArticleSectionLeft.propTypes = {
    images: PropTypes.array,
    body: PropTypes.string
};

export default ArticleSectionLeft;
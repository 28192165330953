import React from 'react';

import PropTypes from "prop-types";

import { useNavigate } from 'react-router';

const ArticleAssetFeatured = ( { id, title, thumbnail, publish_date, excerpt } ) => {

    const navigate = useNavigate();

    const handleOnView = () => {

        navigate(`/news/${ id }`)
    }

    return(
        <div className="rounded-10 hover-border-admin-accent w-100 h-100 d-flex bg-cover overflow-hidden" onClick={ handleOnView } style={{minHeight:300, border:'1px solid #ebebeb', backgroundImage: `url(" ${ thumbnail || "" } ")` }}>
            <div className={`w-100 mt-auto px-2 pt-4 pb-2 d-flex flex-column`} style={{ gap:6, background: 'linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%)' }}>
                <h4 className="bold upper text-white">{ title || '' }</h4> 
                <p className="small text-light-gray">{ excerpt ? `${excerpt.substring( 0, 96 )}...` : '' }</p>
                { publish_date && <h5 className="text-gray small">{ new Date( publish_date ).toLocaleDateString( 'en-us', { day:"numeric", month:"short", year:"numeric" } ) }</h5> }
            </div>
        </div>
    )
}

ArticleAssetFeatured.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    publish_date: PropTypes.string,
    excerpt: PropTypes.string
}

export default ArticleAssetFeatured;
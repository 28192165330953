import React from 'react';

import PropTypes from "prop-types";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUser as I } from '@fortawesome/pro-solid-svg-icons'

import Icon from './Icon';

const UserIcon = ({ style, className }) => {
    return <Icon style={ style } className={ className }>
        <FontAwesomeIcon icon={ I } />
    </Icon>
}

UserIcon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

export default UserIcon
import React from "react";

export const Spinner = () => {
  return (
    <div className="loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
import React from 'react';

import PropTypes from 'prop-types';

const StockXLogo = ( { width } ) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width || 35 } height={ width || 35 } viewBox="0 0 35 35">
            <path fill="currentcolor" d="M19.99,24.06l12.51,10.94v-8.75l-10.01-8.75,10.01-8.75V0L15.01,15.3v-4.36L2.5,0V8.75l10.01,8.75L2.5,26.25v8.75l17.49-15.3v4.36Z"/>
        </svg>
    )
}

StockXLogo.propTypes = {
    width: PropTypes.number
}

export default StockXLogo

export const capitalizeFirstLetter = ( string ) => {
    
    return string.charAt( 0 ).toUpperCase() + string.slice( 1 );
}

export const slugify = str => {

    return str.replace( /\s/g, '-' ).toLowerCase();
}

export const getCookie = function( name ){

    let cookieValue = null;

    if( document.cookie && document.cookie !== '' ){

        const cookies = document.cookie.split( ';' );

        for ( let i = 0; i < cookies.length; i++ ) {

            const cookie = cookies[ i ].trim();

            if ( cookie.substring( 0, name.length + 1 ) === ( name + '=' ) ) {
                
                cookieValue = decodeURIComponent( cookie.substring( name.length + 1 ) );

                break;
            }
        }
    }
    
    return cookieValue;
}




import React, { useState } from 'react';

import PropTypes from "prop-types";

const defaultState = {
    current_windows: []
}

const WindowStateContext = React.createContext();

const useWindowState = () => {

    const context = React.useContext( WindowStateContext );

    if( context === undefined ) throw new Error('useWindowState must be used within a WindowProvider');

    return context
}
  
const WindowProvider = ( { children } ) => {

    return (
        <WindowStateContext.Provider value={ useState( defaultState ) }>
            { children }
        </WindowStateContext.Provider>
    )
}

WindowProvider.propTypes = {
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { useWindowState, WindowProvider };
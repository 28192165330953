export const stateHandler = ( state, action, handlers ) => {

    let _state = { ...state }

    if( handlers[ action.type ] ){

        handlers[ action.type ]( _state, action );

        return _state;

    } else {

        throw new Error( `${ action.type }, handler not found` );
    }
}

export const setVarStateHandler = ( state, action ) => {

    let _state = { ...state }, _key = action.type.substring(3);
    
    _key = _key.charAt(0).toLowerCase() + _key.slice(1);

    if( _state[ _key ] === undefined ) throw new Error(`State key '${_key}' not found.`);

    _state[ _key ] = action.value;

    return _state;
}
import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Route, Routes } from 'react-router';

import ProductsScreen from './components/screens/ProductsScreen';

import ReleasesScreen from './components/screens/ReleasesScreen';

import ArticlesScreen from './components/screens/ArticlesScreen';

import HomeScreen from './components/screens/HomeScreen';

import HeaderNavigation from './components/HeaderNavigation';

import { ProductsProvider } from '../global/providers/ProductsProvider';

import { ArticlesProvider } from '../global/providers/ArticlesProvider';

import { ReleasesProvider } from '../global/providers/ReleasesProvider';

import ProductDetailScreen from './components/screens/ProductDetailScreen';

import ReleaseDetailScreen from './components/screens/ReleaseDetailScreen'; 

import ArticleDetailScrreen from './components/screens/ArticleDetailScreen';

import { PagesProvider } from '../global/providers/PagesProvider';

import DownloadScreen from './components/screens/DownloadScreen';

import LoadMoreScrollingModule from './components/LoadMoreScrollingModule';

const Web = ( { images, embed } ) => {

    const [ loadNextIterations, setLoadNextIterations ] = useState( 0 );

    const loadNextIterations_ref = useRef( loadNextIterations );
    
    useEffect( () => {
        
        loadNextIterations_ref.current = loadNextIterations;
        
    }, [ loadNextIterations ] )
    
    const handleOnLoadNext = () => {

        setLoadNextIterations(  loadNextIterations_ref.current + 1 )
    }

    return(
        <PagesProvider loadNextIterations={ loadNextIterations }>
        <ProductsProvider>
        <ArticlesProvider>
        <ReleasesProvider>
            <div id="web" className="cover d-flex flex-column">
                { !embed && <HeaderNavigation logo_url={ images.sslogo } /> }
                
                <LoadMoreScrollingModule className="screen-container overflow-y-auto flex-grow-1" onLoadNext={handleOnLoadNext}>
                    <div className="d-flex flex-column align-items-center" style={{ backgroundColor:'#fbfbfb', minHeight:'100%' }}>
                        <Routes>
                            <Route exact path="/sneakers/:productId" element={ <ProductDetailScreen /> } />
                            <Route exact path="/releases/:releaseId" element={ <ReleaseDetailScreen /> } />
                            <Route exact path="/news/:articleId" element={ <ArticleDetailScrreen /> } />
                            <Route path="/sneakers?s=:searchStr" element={ <ProductsScreen /> } />
                            <Route path="/sneakers/*" element={ <ProductsScreen /> } />
                            <Route path="/releases/*" element={ <ReleasesScreen /> } />
                            <Route path="/news/*" element={ <ArticlesScreen /> } />
                            <Route exact path="/download" element={ <DownloadScreen image_location={ images.location } /> } />
                            <Route exact path="/" element={ <HomeScreen /> } />
                        </Routes>
                        { !embed && <div className="flex-grow-1 d-flex align-items-end w-100" >
                            <div className="d-flex flex-column bg-white p-2 gap-20 w-100" style={{borderTop:'1px solid #ebebeb'}}>
                                <div className="d-flex w-100">
                                    <div className="d-flex gap-10 align-items-center"><img src={ images.sslogo } /><h5 className="upper bold">Sneaker Seeker</h5></div>
                                    <div className="ml-auto d-flex small" style={{gap:10}}>
                                        <a href="https://staging.sneakerseeker.io/terms-and-conditions">Terms and conditions</a> |
                                        <a href="https://staging.sneakerseeker.io/privacy-policy">Privacy policy</a>
                                    </div>
                                </div>
                                <p className="w-100 text-gray tiny">
                                    Sneaker Seeker may receive a share of the sale if you click and purchase through one of our links on any of the posts throughout the website.
                                </p>
                            </div>
                        </div> }
                    </div>
                </LoadMoreScrollingModule>
            </div>
        </ReleasesProvider>
        </ArticlesProvider>
        </ProductsProvider>
        </PagesProvider>
    )
}

Web.propTypes = {
    images: PropTypes.object,
    embed: PropTypes.bool
}

export default Web

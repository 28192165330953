import React, { useState, useEffect } from 'react';

import PropTypes from "prop-types";

import AngleRightIcon from '../../global/components/icon/AngleRightIcon';

const SizeSelectorModule = ( { pricing, selectedSize, onSizeSelected } ) => {

    const [ open, setOpen ] = useState( false );

    useEffect(()=>{
        setOpen(false)
    },[selectedSize])

    return (
        <div className="rounded-10 overflow-hidden bg-white p-1 d-flex flex-column gap-20" style={{border:'1px solid #ebebeb'}}>
            <div className="d-flex w-100 pointer" onClick={ () => setOpen(!open)} >
                <h6 className="pr-1 flex-grow-1">Selected Size</h6>
                <div className="d-flex flex-grow-1 align-items-center pl-1" style={{borderLeft:'1px solid #ebebeb'}}>
                    <h6 className="">{ selectedSize || "All"}</h6>
                    <span className="ml-auto"><AngleRightIcon style={{transform:'rotate(90deg)'}} /></span>
                </div>
            </div>
            <div className="d-flex flex-wrap cover align-items-start " style={{ gap: 4, display: open ? 'flex' : 'none' }}>
                <button disabled={ !selectedSize } onClick={ () => onSizeSelected( null ) } className={`d-flex p-1 hover-border-gray text-center justify-content-center rounded-8 bg-${selectedSize ? 'white' : 'go'}`} style={{ flex:'1 0 calc( 20% - 4px )', border:"1px solid #ebebeb" }}>
                    <h6 className="bold small">{ 'All' }</h6>
                </button>
                { pricing && Object.keys( pricing ).sort( (a,b) => Number( a ) > Number( b ) ? 1 : -1 ).map( size => {
                    let selected = selectedSize == size;
                    return <button disabled={ selected } onClick={ () => onSizeSelected( size ) } key={`size-${ size.replace('.','-') }`} className={`d-flex p-1 hover-border-gray text-center justify-content-center rounded-8 bg-${selected ? 'go' : 'white'}`} style={{ border:"1px solid #ebebeb", flex:'1 0 calc( 20% - 4px )' }}>
                        <h6 className="bold small">{ size }</h6>
                    </button>
                } ) } 
            </div>
        </div>
    )
}


SizeSelectorModule.propTypes = {
    pricing: PropTypes.object,
    selectedSize: PropTypes.string,
    onSizeSelected: PropTypes.func.isRequired
}

export default SizeSelectorModule;
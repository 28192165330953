import React from 'react';

import PropTypes from "prop-types";

const Icon = ({ style, className, children }) => {
    return <div style={{ ...style }} className={ `d-flex align-items-center justify-content-center ${className || ''}` } >
        { children }
    </div>
}

Icon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.object
};

export default Icon